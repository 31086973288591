<template>
  <router-view />
</template>
<script>
import { registerDataManagementModuleContext } from '@/modules/dataManagement'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'DataManagement',
  setup () {
    registerDataManagementModuleContext()
  }
})
</script>

<style lang="scss" scoped>
.data-management-wrapper {
  height: calc(100vh - #{$header-height});
  position: absolute;
  right: 0;
  top: $header-height;
  width: 100%;
}

.main {
  min-height: calc(100vh - 136px);
  min-height: calc(100vh - #{$header-height});
  padding-bottom: 64px;
  padding-top: 32px;
}
</style>
